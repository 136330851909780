var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { class: _vm.dropdownClasses }, [
    _c(
      "a",
      {
        staticClass: "c-sidebar-nav-dropdown-toggle custom-hover",
        class: [
          _vm.isArtist(_vm.group) ? "c-artist-sidebar" : "c-rep-sidebar",
          { "dropdown-archive": _vm.groupParent }
        ],
        style: _vm.archive ? { paddingLeft: "65px" } : {},
        on: { click: _vm.handleClick }
      },
      [
        _vm.icon
          ? _c("CIcon", _vm._b({}, "CIcon", _vm.computedIcon, false))
          : _vm._e(),
        _vm.fontIcon
          ? _c("i", { class: ["c-sidebar-nav-icon", _vm.fontIcon] })
          : _vm._e(),
        _vm._v(" " + _vm._s(_vm.name) + " "),
        _vm.isArtist(_vm.group)
          ? [
              _vm.badge && _vm.badge.text
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top",
                          value: "Pending Revisions",
                          expression: "'Pending Revisions'",
                          modifiers: { hover: true, top: true }
                        }
                      ],
                      class: "sidebar-badge badge " + "badge-" + _vm.badge.text,
                      staticStyle: { "margin-top": "4px !important" }
                    },
                    [_vm._v(_vm._s(_vm.pendingVersions[_vm.badge.text]))]
                  )
                : _vm._e()
            ]
          : [
              _vm.badge && _vm.badge.text
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top",
                          value: "Pending Orders",
                          expression: "'Pending Orders'",
                          modifiers: { hover: true, top: true }
                        }
                      ],
                      class: "sidebar-badge badge " + "badge-" + _vm.badge.text,
                      staticStyle: { "margin-top": "1px !important" }
                    },
                    [_vm._v(_vm._s(_vm.pendingOrders[_vm.badge.text]))]
                  )
                : _vm._e()
            ]
      ],
      2
    ),
    _c(
      "ul",
      {
        staticClass: "c-sidebar-nav-dropdown-items",
        on: { click: _vm.itemClicked }
      },
      [
        _vm._t("default", [
          _vm._l(_vm.items, function(item, key) {
            return [
              _vm.isNestedDropdown(item)
                ? _c(
                    "CXSidebarNavDropdown",
                    _vm._b({ key: key }, "CXSidebarNavDropdown", item, false)
                  )
                : _c(
                    "CSidebarNavItem",
                    _vm._b({ key: key }, "CSidebarNavItem", item, false)
                  )
            ]
          })
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }